const config = {
  // API Base URL
  // API_BASE_URL: "https://halo365-bare.us-east-1.elasticbeanstalk.com:445/api/",
  API_BASE_URL: process.env.REACT_APP_BASE_URL,
  // API_BASE_URL: "http://localhost:8001/api/",
  // API endpoints
  endpoints: {
    ADMIN: {
      AUTH: {
        LOGIN: "/auth/admin/authenticate",
        VERIFY_OTP: "/auth/admin/verify-otp",
        SEND_OTP: "/auth/admin/send-otp",
        REFRESH_TOKEN: "/auth/admin/refresh-token"
      },
    },
    COMPANY: {
      AUTH: {
        LOGIN: "/auth/company/authenticate",
        VERIFY_OTP: "/auth/company/verify-otp",
        SEND_OTP: "/auth/company/send-otp",
        REFRESH_TOKEN: "/auth/company/refresh-token"
      },
    },
    PROFESSIONS: {
      LIST: "/professions", // Endpoint to get the list of professions
      GET: (id) => `/professions/${id}`, // Endpoint to get a single profession by ID
      CREATE: "/professions", // Endpoint to create a new profession
      UPDATE: (id) => `/professions/${id}`, // Endpoint to update a profession by ID
      DELETE: (id) => `/professions/${id}`, // Endpoint to delete a profession by ID
    },
    COMPANY_USER_ADDRESS: {
      GET: (userId) => `/address/professional/${userId}`,
      CREATE: (userId) => `/address/professional/${userId}`, 
      UPDATE: (addrId,userId) => `/address/${addrId}/professional`, 
      DELETE: (id) => `/professional/${id}`, 
    },
    COMPANY_ADDRESS: {
      GET: (compId) => `/address/company/${compId}`,
      CREATE: (compId) => `/address/company/${compId}`, 
      UPDATE: (addrId,userId) => `/address/${addrId}/company`, 
      DELETE: (id) => `/professional/${id}`, 
    }

  },
  IS_COMP:(process.env.REACT_APP_IS_COMP=="true")
};

export default config;
